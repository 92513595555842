.setting-page-div {
  padding: 56px 252px;
}

.setting-btn {
  border: none;
  display: flex;
  justify-content: space-between;
  box-shadow: none;
  align-items: center;
  padding: 4px 8px;
}

.setting-btn:hover {
  color: #22b5e9 !important;
}

.setting-btn img {
  margin-left: 10px;
  margin-right: 4px;
  width: 14px;
  height: 14px;
}

.setting-upload
  .ant-upload-wrapper
  .ant-upload-list
  .ant-upload-list-item:hover {
  background-color: transparent !important;
}
.ant-upload-list-item-action {
  opacity: inherit !important;
}

.setting-upload .ant-upload-wrapper .ant-upload-list .ant-upload-list-item {
  height: 12px;
}

.setting-acc-info-div {
  font-size: 12px;
  color: var(--kilde-blue);
}

.setting-acc-info-div a {
  color: var(--kilde-blue);
  text-decoration: underline;
}

.add-doc-setting {
  font-size: 12px;
  color: #1a202ccc;
  line-height: 18px;
}

.add-doc-setting-text {
  color: #1a202c;
  font-size: 14px;
  line-height: 20px;
}

.setting-upload .ant-upload .ant-btn {
  width: 100%;
  height: 100%;
}

.setting-upload .ant-upload {
  width: 100%;
  height: 100%;
}

.setting-upload .setting-custom-default-btn {
  width: auto;
}

.setting-upload .setting-main-upload-btn {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 25px;
  border: none;
  box-shadow: none;
  background: #1a202c05;
}

.setting-upload .setting-main-upload-btn div {
  color: #1a202c66;
  font-size: 12px;
}

.setting-upload .ant-upload-list-item-container {
  margin-top: 15px;
}

.setting-upload .anticon svg {
  color: var(--kilde-blue);
  display: inline-block;
  width: 16px;
  height: 16px;
}

.remove-modal-back-btn {
  border-radius: 12px;
  border: 1px solid var(--kilde-blue);
  padding: 6px 16px 8px 16px;
  height: 36px;
  color: var(--kilde-blue);
}

.remove-modal-back-btn:hover {
  border: 1px solid var(--kilde-blue) !important;
  color: var(--kilde-blue) !important;
}

.authenticator-app-number {
  border: 1px solid #1a202c0d;
  border-radius: 50%;
  text-align: center;
  color: #1a202c66;
  width: 28px;
  height: 28px;
}

.authenticator-app-btn-div {
  display: flex;
  gap: 10px;
}

.scretkey-div {
  align-items: center;
  gap: 10px;
  margin-left: 38px;
}

.secret-input {
  border-radius: 12px;
  padding: 6px 16px 8px 16px;
}

.authenticate-otp-input-div {
  display: flex;
  justify-content: flex-start;
}

.authenticate-otp-input-div input {
  margin-left: 8px;
  margin-right: 8px;
}

.authenticator-checkcircle {
  width: 32px;
  height: 32px;
  color: var(--kilde-blue);
}

.infomation-profile-div {
  border-radius: 16px;
  padding: 40px !important;
  background: #ffffff;
  margin-bottom: 16px;
}

.setting-head {
  font-size: 24px;
  font-weight: 600;
}

.setting-twofa-div {
  border-radius: 16px;
  padding: 28px !important;
  background: #ffffff;
  max-width: 612px;
}

.settitng-totp {
  border-radius: 16px;
  padding: 24px !important;
  background: #ffffff;
  width: 100%;
}

.twofa-maindiv {
  display: flex;
  max-width: 460px;
  padding: var(--28, 28px);
  flex-direction: column;
  align-items: center;
  border-radius: var(--16, 16px);
  background: var(--white-100, #fff);
}

.auth-head {
  line-height: 20px;
  font-weight: 600;
  font-size: 14px;
}

.authenticator-app-sub-div {
  display: flex;
  align-items: flex-start;
  gap: 12px;
}

.authenticator-app-success-sub-div {
  display: flex;
  align-items: center;
  gap: 12px;
}

.normal-padd-right {
  padding-right: 16px !important;
}

.media-margin {
  margin-bottom: 16px !important;
}

.setting-page-sub-div {
  color: var(--black-80, rgba(26, 32, 44, 0.8));
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  max-width: 500px;
}

.add-doc-input-head {
  color: var(--kilde-blue);
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
}

.twofa-banner-div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px;
  gap: 8px;
  margin-bottom: 24px;
  position: relative;
  border-radius: var(--16, 16px);
  background: var(--white-100, #fff);
  box-shadow: 2px 2px 34px 0px rgba(47, 47, 47, 0.05);
}

.twofa-subdiv {
  display: flex;
}

.twofa-p-enable {
  margin-top: 0px;
  margin-left: 38px;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  color: #52cb98;
}

.twofa-p-disable {
  margin-top: 0px;
  margin-left: 38px;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  color: var(--Secondary-Red, #ff4747);
}

.twofa-setup-button {
  display: flex;
  padding: var(--8, 8px) var(--16, 16px);
  justify-content: center;
  align-items: center;
  gap: var(--8, 8px);
  border-radius: var(--12, 12px);
  background: var(--Primary-Blue, #22b5e9);
  color: #ffffff;
  width: 95px;
  height: 36px;
}

.twofa-setup-button:hover {
  color: #ffffff !important;
}

.twofa-whatisit {
  display: flex;
  padding: var(--8, 8px) var(--16, 16px);
  justify-content: center;
  align-items: center;
  gap: var(--8, 8px);
  align-self: stretch;
  border-radius: var(--12, 12px);
}

.twofa-whatisit p {
  color: var(--Secondary-Indigo, #22b5e9);
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  text-decoration-line: underline;
}

.twofa-main {
  display: flex;
}

.twofa-margin {
  margin-right: 16px;
}

.twofa-margin-left {
  margin-left: 16px;
}

.setting-twofa {
  display: flex;
  padding: var(--28, 28px);
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  border-radius: var(--16, 16px);
  background: var(--white-100, #fff);
  margin-bottom: 16px;
  max-width: 460px;
  flex: 1 1 460px;
}

.twofa-second-div {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.twofa-tag {
  color: var(--black-40, rgba(26, 32, 44, 0.4));
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
}

.twofa-sms-p {
  color: var(--black-100, #1a202c);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
}

.twofa-sms-no {
  color: var(--black-40, rgba(26, 32, 44, 0.4));
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
}

.twofa-thirdsubdiv {
  color: var(--kilde-blue);
  margin-top: 8px;
}

.authenticate-disp-div {
  margin-left: 30px;
  display: flex;
  flex-direction: row;
  gap: 8px;
}

.edit-address-modal .ant-modal-content {
  display: flex;
  width: 612px;
  padding: var(--40, 40px);
  flex-direction: column;
  align-items: flex-start;
  gap: var(--24, 24px);
  border-radius: var(--16, 16px);
  background: var(--white-100, #fff);
}

.edit-address-modal label {
  color: var(--black-40, rgba(26, 32, 44, 0.4));
  font-feature-settings: "ss01" on, "cv01" on, "cv11" on;
  font-family: "Inter Tight";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.edit-address-status {
  display: flex;
  padding: var(--16, 16px) var(--12, 12px);
  align-items: center;
  align-self: stretch;
  border-radius: var(--16, 16px);
  border: 1px solid rgba(255, 233, 153, 0.4);
  background: rgba(255, 233, 153, 0.3);
}

.edit-address-text {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  margin: 0;
  margin-left: 4px;
}

.editAddress-twofa .ant-modal-content {
  display: flex;
  padding: var(--40, 40px);
  flex-direction: column;
  align-items: center;
  gap: var(--24, 24px);
  border-radius: var(--16, 16px);
  border: 1px solid rgba(26, 32, 44, 0.1);
}

.editAddress-twofa-subtext {
  color: var(--black-40, rgba(26, 32, 44, 0.4));
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
}

.typeform-share-link {
  border-radius: 12px;
  background: var(--kilde-blue);
  padding: 6px 16px 8px 16px;
  height: 36px;
  border: none;
  color: var(--white-100, #fff);
  cursor: pointer;
}

.copy-btn-tag {
  display: flex;
  align-items: center;
  gap: 4px;
  margin-top: 5px;
  color: rgba(0, 0, 0, 0.25);
}

@media (max-width: 1200px) {
  .setting-page-div {
    padding: 56px 250px;
  }
}
@media (max-width: 1150px) {
  .setting-page-div {
    padding: 56px 200px;
  }
}
@media (max-width: 1001px) {
  .setting-page-div {
    padding: 56px 170px;
  }
}

@media (max-width: 944px) {
  .setting-page-div {
    padding: 56px 150px;
  }
}
@media (max-width: 895px) {
  .setting-page-div {
    padding: 56px 85px;
  }
}

@media (max-width: 768px) {
  .setting-page-div {
    padding: 56px 20px;
  }
  .normal-padd-right {
    padding-right: 0 !important;
  }
  .media-margin {
    margin-bottom: 0;
  }
}

@media (max-width: 1140px) and (min-width: 991px) {
  .authenticate-otp-input-div input {
    margin-left: 4px;
    margin-right: 4px;
    width: 38px;
    height: 38px;
  }
}

@media (max-width: 576px) {
  .authenticate-otp-input-div input {
    margin-left: 2px;
    margin-right: 2px;
  }

  .enable-totp-btn {
    margin-top: 15px;
  }

  .infomation-profile-div {
    border-radius: 16px;
    padding: 28px !important;
    background: #ffffff;
  }
  .setting-twofa-div {
    padding: 20px !important;
  }

  .authenticator-app-sub-div {
    flex-direction: column;
  }
  .authenticator-app-btn-div {
    justify-content: space-between;
  }
  .authenticator-app-btn-div .auth-app-btn-div {
    width: 100%;
  }

  .scretkey-div {
    margin-left: 0;
  }
  .twofa-main {
    display: block;
  }

  .twofa-margin {
    margin-right: 0px;
  }

  .twofa-margin-left {
    margin-left: 0px;
  }

  .twofa-subdiv {
    display: block;
  }
  .twofa-banner-div {
    display: block;
  }

  .twofa-secondsubdiv {
    display: flex;
    align-items: center;
  }
  .twofa-thirdsubdiv {
    margin-bottom: 12px;
  }

  .authenticate-disp-div {
    flex-direction: column;
    margin-left: 0;
  }
}
