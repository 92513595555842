.password-hint {
  opacity: 0;
  max-height: 0;
  overflow: hidden;
  transform: translateY(-10px);
  transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out,
    max-height 0.5s ease-in-out;
}

.password-hint.show {
  opacity: 1;
  max-height: 200px; /* Adjust this value to match content */
  transform: translateY(0);
}
