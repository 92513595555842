.sb-TwoFa-center .ant-qrcode {
  width: 112px;
  height: 113.4px;
  padding: 0;
  border: none;
  border-radius: 0px;
}

.sb-TwoFa-center .ant-qrcode svg {
  width: 112px;
  height: 113.4px;
}

.sb-TwoFa-center {
  display: flex;
  justify-content: center;
}

.sb-TwoFa-actions {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.sb-TwoFa-title {
  color: var(--black-100, #1a202c);
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}

.edit-address-title {
  color: var(--black-100, #1a202c);
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  text-align: start !important;
}

.editAddress {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
}

.twofa-modal-closeicon .anticon svg {
  color: var(--kilde-blue);
}

.sb-TwoFa-subtitle {
  color: var(--black-40, rgba(26, 32, 44, 0.4));
  text-align: center;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
}

.sb-twofa-link {
  color: var(--Primary-Blue, #22b5e9);
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
}

.sb-twofa-link a {
  color: var(--Primary-Blue, #22b5e9);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  text-decoration-line: underline;
}

.kd-2fa-firstdiv {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--12, 12px);
  align-self: stretch;
}

.kd-2fa-dothislater {
  display: flex;
  padding: var(--8, 8px) var(--16, 16px);
  justify-content: center;
  align-items: center;
  gap: var(--8, 8px);
  border-radius: var(--12, 12px);
  border: 1px solid var(--black-20, rgba(26, 32, 44, 0.2));
  color: var(--black-100, #1a202c);
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  margin-top: 4px;
  height: 36px;
}

.kd-resend {
  color: var(--Primary-Brand, #1a202c);
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
}

.kl-mobile {
  width: 80px;
  height: 80px;
}

.kl-link {
  font-size: 14px;
  color: var(--kilde-blue);
}

.sb-otp-input-div {
  display: flex;
  justify-content: center;
}

.twofa-addresschange {
  margin: auto;
  text-align: center;
  padding: 40px;
  border-radius: var(--24, 24px);
  background: var(--white-100, #fff);
}

.otp-input-2fa {
  display: flex;
  width: var(--40, 40px);
  height: var(--40, 40px);
  padding: var(--4, 4px) var(--12, 12px);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: var(--4, 4px);
  border-radius: var(--12, 12px);
  border: 1px solid var(--Primary-Blue, #22b5e9);
  background: var(--white-80, rgba(255, 255, 255, 0.8));
}

.focus {
  border: 1px solid var(--Primary-Blue, #22b5e9) !important;
}

.setting-twofa-modal {
  display: flex;
  padding: var(--20, 20px);
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  border-radius: var(--16, 16px);
  background: var(--white-100, #fff);
  flex: 1 1 460px;
  border: 1px solid var(--black-10, rgba(26, 32, 44, 0.1));
  margin: 16px 0px 16px 0px;
}

.twofa-or {
  display: block;
  align-content: center;
  font-weight: bold;
}

@media (max-width: 576px) {
  .twofa-or {
    display: flex;
    justify-content: center;
  }
}
