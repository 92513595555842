.wallet-maindiv {
  padding: 56px 56px 56px 252px;
}

.wallet-sub-head {
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
}
.wallet-tab .ant-tabs-nav {
  margin-bottom: 20px;
}

.wallet-label {
  color: var(--black-40, rgba(26, 32, 44, 0.4));
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

.deposit-list ol {
  margin: 0;
  padding-inline-start: 12px;
}

.deposit-list-tag {
  color: var(--black-80, rgba(26, 32, 44, 0.8));
  font-size: 12px;
  line-height: 18px;
  margin: 0;
}

.deposit-bank-transffer-detail-div {
  border-radius: 8px;
  border: 1px solid var(--white-100, #fff);
  background: var(--Primary-Background, #f8f7f2);
  padding: 24px;
}

.deposit-list-title {
  font-size: 12px;
  color: #1a202c;
  line-height: 18px;
}

.balance-currency-head {
  font-size: 14px;
  font-weight: 600;
  color: #1a202c66;
  line-height: 20px;
}

.wallet-info-div {
  border-radius: 16px;
  padding: 40px;
  background: #ffffff;
}

.wallet-tab .ant-tabs-tab + .ant-tabs-tab {
  margin: 0 0 0 16px;
}

.wallet-addbank-modal .ant-modal-content {
  border-radius: 16px;
}

.wallet-addbank-modal .ant-modal-close {
  top: 23px;
  right: 20px;
  color: var(--kilde-blue);
}

.wallet-addbank-modal label {
  color: #1a202c66;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

.wallet-account-no-div {
  gap: 8px;
  display: flex;
  align-items: center;
}
.wallet-account-no-div p {
  font-size: 14px;
  color: #1a202c66;
  line-height: 20px;
  margin: 0;
}

.dotsThreeBtn {
  padding: 0;
  height: auto;
  border: none;
  display: flex;
  align-items: center;
  box-shadow: none;
  background: transparent;
}

.withraw-cancelbtn {
  color: #ff4747;
  border: 1px solid #ff4747;
  border-radius: 8px;
}
.withraw-cancelbtn:hover {
  color: #ff4747 !important;
  border-color: #ff4747 !important;
}

.ant-popover {
  --antd-arrow-background-color: transparent;
  border-radius: 16px;
  border: 1px solid var(--black-10, rgba(26, 32, 44, 0.1));
  background: var(--white-80, rgba(255, 255, 255, 0.8));
  backdrop-filter: blur(20px);
}

:where(.css-dev-only-do-not-override-19gw05y).ant-popover .ant-popover-inner {
  border-radius: 16px;
  box-shadow: none;
  padding: 12px;
}

.withdraw-modal .ant-modal-content {
  padding: 40px;
  border-radius: 16px;
}

.withdraw-status-div {
  display: flex;
  gap: 5px;
  align-items: center;
  font-size: 12px;
}

.withdraw-status-card-div {
  display: flex;
  gap: 5px;
  align-items: center;
  font-size: 12px;
  justify-content: flex-end;
}

.progress-p {
  color: var(--kilde-blue);
}

.settled-p {
  color: #4aa785;
}
.failed-p {
  color: #ff4747;
}

.progress-circle-span {
  width: 5px;
  border-radius: 50%;
  height: 5px;
  background-color: #22b5e933;
}
.settled-circle-span {
  width: 5px;
  border-radius: 50%;
  height: 5px;
  background-color: #a1e3cb;
}

.failed-circle-span {
  width: 5px;
  border-radius: 50%;
  height: 5px;
  background-color: #fa7070;
}

.withdraw-card {
  background: var(--white);
  padding: 16px;
  border-radius: 16px;
}

.withdraw-card-head {
  display: flex;
  gap: 4px;
  align-items: center;
}

.withdraw-card-heading-data {
  color: #1a202c;
  font-size: 12px;
  line-height: 18px;
  display: flex;
  gap: 4px;
}

.withraw-card-first-col {
  padding: 8px 8px 8px 0px;
}

.withraw-card-sec-col {
  padding: 8px 0px 8px 8px;
}

.wallet-unactive-ins {
  font-size: 14px;
  color: #1a202c66;
  line-height: 20px;
}

.view-account-div {
  width: 100%;
  justify-content: flex-end;
  display: flex;
}

.curr-exchange-span {
  color: var(--black-40, rgba(26, 32, 44, 0.4));
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
}

.currency-bold {
  font-weight: bold;
  text-decoration: underline;
}

.full-width-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  margin: 0;
  gap: 16px;
}

.bank-info-card {
  flex: 0 0 calc(50% - 8px);
  box-sizing: border-box;
  border-radius: var(--16, 16px);
  background: rgba(26, 32, 44, 0.02);
  display: flex;
  height: 168px;
  min-width: 200px;
  padding: var(--16, 16px);
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
}

.bank-info-card-edit-div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.bank-info-card-sub-div {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.acc-number-info-div {
  display: flex;
  align-items: center;
  gap: 8px;
}

.account-number-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.account-number-tag {
  color: var(--black-100, #1a202c);
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}

.holder-name-tag {
  color: var(--black-100, #1a202c);
  font-size: 12px;
  font-weight: 600;
  line-height: 18px;
}

.masked-account-number {
  display: flex;
  justify-content: center;
  align-items: center;
}

.deposit-sub-heading {
  font-size: 13px;
  /* margin-bottom: 10px; */
}

.copy-button {
  border: none;
  background: transparent;
  box-shadow: none;
  cursor: pointer;
  padding-right: 0;
  padding-left: 1px;
}

.hover-blue:hover {
  color: var(--kilde-blue);
  cursor: pointer;
}

.radio-button-active {
  min-width: 245px;
  max-width: 272px;
  padding: var(--16, 16px);
  border-radius: var(--16, 16px);
  border: 1px solid var(--kilde-no-use-22-b-5-e-9, #22b5e9);
  background: var(--white-80, rgba(255, 255, 255, 0.8));
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.deposit-div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: var(--20, 20px);
  gap: var(--12, 12px) var(--8, 8px);
  border-radius: var(--12, 12px);
  border: 1px solid var(--black-10, rgba(28, 28, 28, 0.1));
  background: var(--white-100, #fff);
  margin-bottom: 8px;
  width: 100%;
}
.deposit-head-p {
  color: var(--black-100, #1a202c);
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
}

.deposit-second-p {
  color: var(--black-40, rgba(26, 32, 44, 0.4));
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px; /* 150% */
}

.deposit-third-p {
  color: var(--Primary-Blue, #22b5e9);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px; /* 150% */
}

.paynow-div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
}

.paynow-div p {
  color: var(--black-100, #1a202c);
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 142.857% */
}

.custom-list {
  list-style: none; /* Removes default bullets */
  padding-left: 0;
}

.radio-button-active .ant-radio {
  order: 2;
}

.radio-label {
  order: 1;
  color: var(--black-100, #1a202c) !important;
}

.radio-text {
  color: var(--black-40, rgba(26, 32, 44, 0.4));
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

.addbank-button {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.scanner-div {
  display: flex;
  align-items: flex-start;
  gap: 6px;
}

.error-message {
  color: #e74c3c !important;
  font-size: 12px;
  margin-top: 3px;
  text-align: start;
}

.withdraw-label {
  display: flex;
  justify-content: space-between;
}

.qrdiv {
  display: flex;
  padding: var(--24, 24px);
  flex-direction: column;
  align-items: center;
  border-radius: var(--16, 16px);
  background: var(--white-100, #fff);
  gap: var(--16, 16px);
  width: 224px;
  margin-top: 88px;
}

.qr-maindiv {
  display: flex;
  justify-content: center;
}

.ant-radio-wrapper .ant-radio-checked .ant-radio-inner {
  border-color: var(--kilde-blue);
  background-color: var(--kilde-blue);
}

@media (max-width: 1204px) and (min-width: 991px) {
  .wallet-maindiv {
    padding: 56px 56px 56px 155px;
  }
  .wallet-info-div {
    padding: 35px;
  }
}

@media (max-width: 875px) and (min-width: 765px) {
  .wallet-maindiv {
    padding: 56px 56px 56px 125px;
  }
}

@media (max-width: 640px) and (min-width: 577px) {
  .wallet-maindiv {
    padding: 56px 20px;
  }
}

@media (max-width: 1000px) {
  .scanner-div {
    display: flex;
    flex-direction: column;
  }
  .qrdiv {
    margin-top: 16px;
  }
  .qr-maindiv {
    width: 100%;
  }
}

@media (max-width: 772px) {
  .wallet-maindiv {
    padding: 56px 40px;
  }
  .wallet-info-div {
    padding: 20px;
  }
}

@media (max-width: 576px) {
  .wallet-maindiv {
    padding: 56px 20px;
  }
  .media-wallet-display-div {
    display: flex;
    justify-content: space-between;
  }

  .media-wallet-top-margin {
    margin-top: 16px;
  }
  .wallet-info-div {
    padding: 20px;
  }

  .deposit-bank-transffer-detail-div {
    padding: 16px;
  }

  .media-width {
    width: 100%;
  }

  .wallet-addbank-modal .ant-modal-content {
    padding: 20px;
  }

  .wallet-addbank-modal .ant-modal-close {
    right: 12px;
  }

  .bank-info-card {
    flex: 0 0 calc(100%);
  }
}
