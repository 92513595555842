.auth-drawer-menu-list .ant-menu-item-only-child {
  margin-left: 0px;
}

.ant-menu-submenu-title {
  margin-top: 15px !important;
}

.ant-menu-item {
  margin-top: 15px !important;
}

.ant-notification .ant-notification-notice .ant-notification-notice-close {
  top: 10px;
  inset-inline-end: 10px;
}
